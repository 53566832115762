import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-316a240a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.openBrowser($props.info.newsLink, $props.info.id))),
    button: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, { class: "ion-hide-md-up" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                size: "8",
                style: {"padding-inline-start":"0"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_title, { class: "info-title line-clamp" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.info.title), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _withDirectives(_createVNode(_component_ion_col, {
                size: "4",
                style: {"padding-inline-end":"0"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "rounded-border",
                    src: $props.info.imageLink,
                    referrerpolicy: "no-referrer",
                    crossorigin: "anonymous"
                  }, null, 8, _hoisted_1)
                ]),
                _: 1
              }, 512), [
                [_vShow, $props.info.imageLink && $props.info.imageLink.startsWith('http')]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_header, { class: "ion-hide-md-down" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_ion_col, { size: "1" }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "rounded-border",
                    src: $props.info.imageLink,
                    referrerpolicy: "no-referrer",
                    crossorigin: "anonymous"
                  }, null, 8, _hoisted_2)
                ]),
                _: 1
              }, 512), [
                [_vShow, $props.info.imageLink && $props.info.imageLink.startsWith('http')]
              ]),
              _createVNode(_component_ion_col, { size: "11" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_title, { class: "info-title line-clamp" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.info.title), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, {
            lines: "none",
            style: {"--padding-start":"0","--inner-padding-end":"0"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, {
                class: "ion-text-wrap",
                style: {"margin":"0"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, [
                    _createElementVNode("img", {
                      style: {"vertical-align":"middle"},
                      src: $setup.getInfoSrcImg($props.infoSources, $props.info.source)
                    }, null, 8, _hoisted_3),
                    _createTextVNode("  "),
                    _createElementVNode("small", null, _toDisplayString($props.info.source.split(" ")[0]) + " · " + _toDisplayString($setup.getRelativeDate($props.info.datetime)), 1)
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  ($props.userLoggedIn && $props.info.likedByUser)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.updateUserLikedInfo($props.info, 'remove')), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: $setup.heart,
                            color: "danger"
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  ($props.userLoggedIn && !$props.info.likedByUser)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.updateUserLikedInfo($props.info, 'add')), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: $setup.heartOutline,
                            color: "danger"
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_button, {
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.shareInfo($props.info)), ["stop"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: $setup.shareSocialOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}