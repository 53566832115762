import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e475a26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_skeleton = _resolveComponent("loading-skeleton")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_info_item = _resolveComponent("info-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!

  return ($setup.loading || $setup.delayLoad)
    ? (_openBlock(), _createBlock(_component_loading_skeleton, {
        key: 0,
        imgSlot: "start"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ion_toolbar, {
          style: {"padding":"0 10px"},
          class: "sticky"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_segment, {
              mode: $props.segmentMode || 'ios',
              modelValue: $setup.newsFilter,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.newsFilter) = $event)),
              onIonChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.onKeywordChanged($event.target.value, $setup.infoKeywords)))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.infoKeywords, (obj) => {
                  return (_openBlock(), _createBlock(_component_ion_segment_button, {
                    key: obj.keyword,
                    value: obj.keyword,
                    layout: "icon-start"
                  }, {
                    default: _withCtx(() => [
                      (obj.keyword == '寵物活動')
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            icon: $setup.location
                          }, null, 8, ["icon"]))
                        : (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 1,
                            icon: $setup.newspaper
                          }, null, 8, ["icon"])),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.getLocalizedStr(obj, 'title', 'titleEn')), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["mode", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_swiper, {
          modules: $setup.modules,
          onSwiper: $setup.setSwiperInstance,
          onSlideChange: _cache[3] || (_cache[3] = ($event: any) => ($setup.onSlideChanged($event, $setup.infoKeywords))),
          slidesPerView: 1,
          autoHeight: false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_refresher, {
              slot: "fixed",
              onIonRefresh: _cache[2] || (_cache[2] = ($event: any) => ($setup.fetchLatestNews($event)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_refresher_content)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.infoKeywords, (obj) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: obj.keyword
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredInfos($props.infos, obj.keyword).slice(0, $setup.numOfVisibleItems), (info) => {
                        return (_openBlock(), _createBlock(_component_info_item, {
                          key: info.id,
                          info: info,
                          infoSources: $setup.infoSources,
                          userLoggedIn: $setup.userLoggedIn
                        }, null, 8, ["info", "infoSources", "userLoggedIn"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modules", "onSwiper"]),
        _createVNode(_component_ion_infinite_scroll, {
          onIonInfinite: _cache[4] || (_cache[4] = ($event: any) => ($setup.loadData($event, $setup.filteredInfos($props.infos, $setup.newsFilter)))),
          threshold: "100px",
          id: "infinite-scroll"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_infinite_scroll_content, {
              "loading-spinner": "bubbles",
              "loading-text": $setup.t('loadingMoreInfos')
            }, null, 8, ["loading-text"])
          ]),
          _: 1
        })
      ]))
}