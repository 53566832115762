
// icons
import { arrowForward, location, newspaper, } from 'ionicons/icons';

// components
import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

import { IonicSlides, IonInfiniteScroll, IonInfiniteScrollContent, IonButton, IonIcon,
        IonToolbar, IonSegment, IonSegmentButton, IonLabel, IonList, IonRefresher, IonRefresherContent,
        loadingController, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import InfoCard from "@/components/info/InfoCard.vue";
import InfoItem from "@/components/info/InfoItem.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from '@vue/runtime-core';
import { utils } from '@/composables/utils';

export default {
  props: [
    "infos",
    "segmentMode",
    "anchorClass",
  ],
  components: { IonInfiniteScroll, IonInfiniteScrollContent, IonButton, IonIcon,
                IonToolbar, IonSegment, IonSegmentButton, IonLabel, IonList, IonRefresher, IonRefresherContent,
                Swiper, SwiperSlide,
                LoadingSkeleton, InfoCard, InfoItem, },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const { syncFilterBtnPosition, getLocalizedStr, infiniteScrollLoadData, } = utils();

    const userLoggedIn = computed(() => store.state.loggedIn);
    const infoKeywords = computed(() => store.state.infoKeywords);
    const infoSources = computed(() => store.state.infoSources);
    const loading = computed(() => store.state.loadingAppPublicData);
    const delayLoad = ref(true);
    const numOfVisibleItems = ref(15);

    const newsFilter = ref("寵物");
    const segmentBtns = ref([]);
    const slides = ref();
    const setSwiperInstance = (swiper: any) => slides.value = swiper;
    const onKeywordChanged = async (newVal: any, allObjs: any) => {
      const targetIdx = allObjs.findIndex((obj: any) => obj.keyword == newVal);
      slides.value.slideTo(targetIdx);
    }
    const onSlideChanged = async (e: any, allObjs: any) => {
      newsFilter.value = (allObjs[e.activeIndex] || {}).keyword;
      syncFilterBtnPosition(segmentBtns.value, newsFilter.value);
      //document.querySelector('.top-anchor').scrollTop = 0;
      if (document.querySelector(`.${props.anchorClass || 'top-anchor'}`).getBoundingClientRect().top < 0) {
        setTimeout(() => {
          document.querySelector(`.${props.anchorClass || 'top-anchor'}`).scrollIntoView(true);
        }, 250);
      }
    }
    
    const loadData = (ev: any, infos: any) => {
      infiniteScrollLoadData(ev, numOfVisibleItems, infos);
    }

    const filteredInfos = (infos: any, keyword: any) => {
      return infos.filter((info: any) => {
        return info.keyword == keyword;
      });
    }

    // 2. fetch data from API and store data in state variables
    const fetchLatestNews = (e: any = null) => {
      store.dispatch('fetchLatestNews', e);
    };

    onMounted(() => {
      setTimeout(() => {
        delayLoad.value = false;
      }, 1000);
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowForward, location, newspaper,

      // variables
      loading, delayLoad,
      userLoggedIn,
      numOfVisibleItems, infoSources,
      newsFilter, infoKeywords,

      // methods
      t, getLocalizedStr,
      loadData,
      filteredInfos,
      setSwiperInstance, onKeywordChanged, onSlideChanged,
      fetchLatestNews,

      // swiper modules
      modules: [IonicSlides],
    }
  }
}
